import React, { useEffect } from "react";

import { AppLogic } from "src/hooks/useAppLogic";
import Title from "src/components/core/Title";
import routes from "src/routes";

interface LoginProps {
  appLogic: AppLogic;
}

export const Login = (props: LoginProps) => {
  const { appLogic } = props;
  const { portalFlow } = appLogic;

  useEffect(() => {
    portalFlow.goTo(routes.auth.oAuthStart, {}, { redirect: true });
  }, [portalFlow]);

  return (
    <React.Fragment>
      <Title hidden>Log in or Sign up with MyMassGov</Title>
    </React.Fragment>
  );
};

export default Login;
